import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import OurProducts from "../components/Portfolio"

const OurProductsPage = () => (
  <Layout>
    <Seo title="Products" />
    <OurProducts />
  </Layout>
)

export default OurProductsPage
