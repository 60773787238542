import React from "react"
import AnimateHeight from "react-animate-height"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import { Button, Title } from "../ui"
import Inclusion from "./Inclusion"
import "./index.scss"
import { productsData } from "../../constants/productsData"
import { budgetTool } from "../../constants/our-products"
import OurCoreProducts from "./OurCoreProducts"

const OurProducts = () => {
  const [readMore, setReadMore] = React.useState(false)

  const handleShowMoreClick = () => {
    setReadMore(!readMore)
  }

  return (
    <div className="ourProducts__container">
      <Container>
        <Title header="Our Products" color="BlackRussian" />
        <p>
          <br />
          We are on a mission to{" "}
          <b>
            enhance the lives of our customers through simple, convenient, and
            appropriate financial services
          </b>
          .
          <br />
          Our credit-led product offering drives financial inclusion through
          innovation, integrity, teamwork, and our belief in people.
        </p>
        {/* <div className="ourProducts__section">
          <Inclusion />
        </div> */}
        {/* <AnimateHeight
          duration={500}
          height={readMore ? "auto" : 0}
          easing="ease-in-out"
        >
          <div className="ourProducts__subSection">
            <div className="ourProducts__products">
              {
                productsData.map((product, productIndex) => (
                  <p key={productIndex}>
                    <h4 style={{ fontWeight: "bold" }}>{product.title}</h4>
                    {product.description}
                    <ul>
                      {product.items.map((item, itemIndex) => (<li key={itemIndex}>{item}</li>))}
                    </ul>
                  </p>
                ))
              }
              <p>These products and services enhance the lives of our customers and create an ecosystem where people and organisations can get what they need.</p>
            </div>
            <div className="ourProducts__budgetTool">
              <img src={budgetTool.src} alt={budgetTool.alt} />
            </div>
          </div>
        </AnimateHeight> */}
        {/* <Button
          title={readMore ? "Show less" : "Read more"} type="button"
          customClass="ourProducts__button"
          onClick={handleShowMoreClick}
        /> */}
      </Container>
      <OurCoreProducts />
      <div className="ourProducts__portfolio">
        {/* <hr className="ourProducts__divider" />
        <br /> */}
        {/* <p>Fin Group also makes synergistic investments in its proprietary portfolio.</p> */}
        {/* <Link to="/our-portfolio">
          <Button title="Learn more" type="button" customClass="ourProducts__button" />
        </Link> */}
      </div>
    </div>
  )
}

export default OurProducts
