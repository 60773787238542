import React from "react"
import { Container } from "react-bootstrap"
import { Title } from "../../ui"
import OurPortfolioCard from "../../Global/OurPortfolioCard/OurPortfolioCard"
import { allOurCoreProducts } from "../../../constants"
import "./index.scss"

const OurCoreProducts = () => {
  return (
    <div className="ourCoreProducts__container">
      <Container>
        {/* <Title header="Our Core Brands" color="BlackRussian" />
        <p>
          We accelerate financial inclusion in Africa through our core service offering below.
          <br />
          The Fin Group offering is pan-African, and meets the needs of our customers wherever they are.
        </p> */}
        <div className="ourCoreProducts__section">
          {allOurCoreProducts.map(companies => {
            const {
              facebookUrl,
              linkedInUrl,
              id,
              title,
              text,
              image,
              website,
            } = companies

            return (
              <div key={id}>
                <OurPortfolioCard
                  {...{
                    facebookUrl,
                    linkedInUrl,
                    title,
                    text,
                    image,
                    website,
                  }}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default OurCoreProducts
